import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
export const StyledFullBackgroundTextAndAsset = styled.div`
  height: 100%;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;

  .full-background-text-and-asset-content {
    margin: 0 auto;
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
    width: 100%;
    padding: 0px 32px;
    &.top {
      align-self: flex-start;
    }
    &.bottom {
      align-self: flex-end;
    }
  }
`;
